
.main-header {
  color: #6C0AAB;
  font-size: 1.0rem;
  font-weight: bold; 
  letter-spacing: 0.3em;
}

@media (min-width: 576px) { 
  .main-header {
    color: #6C0AAB;
    font-size: 1.5rem;
    font-weight: bold; 
    letter-spacing: 0.3em;
  }
}


.bi {
  vertical-align: -.125em;
  width: 1em;
  height: 1em;
}

h1 {
  color: #6C0AAB;
}
h2 {
  color: #6C0AAB;
}
h3 {
  color: #6C0AAB;
}
h4 {
  color: #6C0AAB;
}
h5 {
  color: #6C0AAB;
}
.heading {
  color: #6C0AAB;
}

.word-wrap {
  /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}