.wishlist-item-overlay {
    opacity: 0.0;
}

.wishlist-item-overlay:hover {
    opacity: 0.85;
    background-color: white;
    overflow: hidden;
    margin: 5px;
}

.wishlist-item-card-container {
    margin: auto;
}

.reserved-rotate {
    color:red;
    writing-mode:vertical-rl;
    border: solid;
  }